@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.test-poppins{
  font-style: italic;
  font-family: 'Poppins';
}

/* NAVBAR */

.navBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  padding: 0 5%;
  display: flex;
  align-items: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  background-color: #EDF2F0;
  z-index: 99;
}

.mr-auto{
  margin-right: auto;
}

.right{
  float: right;
  border: none;
  background-color: #EDF2F0;
}

.navLogo{
  height: 75px;
  cursor: pointer;
}

.navLink{
  font-family: 'Poppins';
  margin: 0 7px;
  font-weight: 500;
  text-decoration: none;
  color: #262931;
  letter-spacing: 1px;
  font-size: 18px;
  position: relative;
}

.navLink:hover{
  color: #616D77;
  cursor: pointer;
}

.navLink::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  background-color: #616D77;
  transition: width 0.4s ease-in-out;
  transform: translateX(-50%);
}

.navLink:hover::before {
  width: 100%;
}

/* BURGER MENU */

.menuIcon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
}

.menuIcon__bar {
  width: 100%;
  height: 2px;
  background-color: #262931;
  transition: background-color 0.3s ease-in-out;
}

.mobileMenu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #EDF2F0;
  padding: 10px;
  text-align: center;
}

.mobileMenu .navLink {
  display: block;
  width: 50%;
  margin: 0 auto;
  margin-bottom: 5px;
}

.menuIcon__bar:nth-child(2) {
  width: 50%;
  background-color: #8c969d;
}

.menuIcon.open .menuIcon__bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menuIcon.open .menuIcon__bar:nth-child(2) {
  opacity: 0;
}

.menuIcon.open .menuIcon__bar:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
}

/* HOME IMAGE */

.fullscreen-div {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./images/kuchnia-na-wymiar.png');
  background-size: cover;
  background-position: center;
  opacity: 0.75;
}

.centered-text {
  text-align: center;
  font-style: italic;
  font-family: 'Poppins';
  z-index: 1;
  color: #262931;
}

.centered-header {
  font-size: 4.5rem;
  letter-spacing: 1px;
  font-weight: 600;
}

.centered-para {
  font-size: 1.5rem;
  font-weight: 400;
}

.arrow-down {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.arrow-down span {
  display: block;
  width: 40px;
  height: 40px;
  border-bottom: 2px solid #EDF2F0;
  border-right: 2px solid #EDF2F0;
  transform: rotate(45deg);
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

.arrow-down:hover span {
  border-color: #262931;
}

/* O NAS */

.section-below {
  height: auto;
  margin: 0 auto;
  padding: 4vh 5vw;
  font-style: italic;
  font-family: 'Poppins';
  color: #262931;
}

.section-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 5vh;
}

.section-header h2,
.section-header h3{
  font-size: 3.9rem;
  font-weight: 600;
  text-align: center;
}

.section-header-about{
  font-size: 2.6rem !important;
  font-weight: 600;
}

.right-column {
  position: relative;
  width: 80%;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  color: #262931;
  text-align: center;
  border-radius: 15px;
  padding: 25px;
}

.right-column::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('./images/sloje.jpeg');
  opacity: 0.3;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  z-index: -1;
}

.right-column h1,
.right-column p {
  position: relative;
  z-index: 1;
}

.right-column p {
  margin:10px 0px;
  font-size: 18px;
}

/* OFERTA */

.kafelki-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.kafelek {
  background-color: #EDF2F0;
  padding: 20px;
  text-align: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}

.kafelek-icon {
  margin-bottom: 10px;
}

.kafelek-img{
  max-width: 70px;
  max-height: 70px;
}

.kafelek-naglowek {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 600;
}

.kafelek-opis {
  font-size: 16px;
}

/* REALIZACJE */

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  height: auto;
  justify-items: center;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  text-align: center;
  min-height: 48vh;
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.kuchnia,
.lazienka,
.szafy,
.biuro {
  position: relative;
}

.text {
  position: relative;
  z-index: 1; 
  background-color:#262931b5;
  padding: 5px;
  border-radius: 15px;
  cursor: pointer;
}

.text h4{
  font-size: 2rem;
  color: #EDF2F0;
  font-weight: 400;
}

.linkStyle {
  text-decoration: none;
}

.kuchnia::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./images/kuchnia-grid.webp");
  border-radius: 10px;
  background-size: cover;
  opacity: 0.4; 
  transition: opacity 0.5s ease;
}

.lazienka::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./images/lazienka-grid.webp");
  border-radius: 10px;
  background-size: cover;
  opacity: 0.4;
  transition: opacity 0.5s ease; 
}

.szafy::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./images/szafy-grid.webp");
  border-radius: 10px;
  background-size: cover;
  opacity: 0.4;
  transition: opacity 0.5s ease; 
}

.biuro::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./images/biuro-grid.jpeg");
  border-radius: 10px;
  background-size: cover;
  opacity: 0.4;
  transition: opacity 0.5s ease; 
}

.kuchnia:hover::before,
.lazienka:hover::before,
.szafy:hover::before,
.biuro:hover::before {
  opacity: 0.9;
  cursor: pointer;
}

/* KONTAKT */

.contact-section{
  height: auto;
  margin: 0 auto;
  padding: 5vh 5vw;
  font-style: italic;
  font-family: 'Poppins';
  color: #262931;
}

.contact-form-container {
  display: flex;
  justify-content: center;
}

.contact-form {
  width: 50%;
  margin-left: 10%;
}

.contact-form form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-form-input {
  width: 70%;
  letter-spacing: 0.7px;
}
.contact-form h3,
.contact-details h3,
.contact-details h4{
  font-size: 1.8rem;
  margin-bottom: 20px;
  font-weight: 500;
}
.contact-details h3,
.contact-details h4{
  margin-bottom: 30px;
}

.contact-form label {
  display: inline-block;
  margin-bottom: 5px;
  margin-top: 5px;
}

.contact-form textarea {
  resize: none;
  height: 200px;
  font-family: 'Poppins';
  font-size: 15px;
}

.char-count{
  font-size: 13px;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #8c969d;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border:2px solid #262931;
}

.contact-form input{
  height: 40px;
  font-family: 'Poppins';
  font-size: 15px;
  margin-bottom: 3px;
}

.error{
  font-size: 12px;
  color: red;
  display: block;
}

.contact-button {
  margin-top: 20px;
  width: 70%;
  padding: 13px;
  font-size: 17px;
  background-color: #262931;
  border: none;
  color: #EDF2F0;
  border-radius: 10px;
  cursor: pointer;
}

.contact-button:hover{
  opacity: 0.7;
  transition: all 0.3s ease-in-out;
}

.contact-details {
  width: 40%;
  padding-left: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contact-gif {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.message-box {
  position: fixed;
  top: 100px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262931e8;
  padding: 15px;
  border-radius: 10px;
  z-index: 9;
}

.message-icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.message-text {
  display: flex;
  font-family: 'Poppins';
  align-items: center;
  color: #EDF2F0;
  font-weight: 400;
}

.close-button {
  position: absolute;
  right: 5px;
  top: 3px;
  background: none;
  border: none;
  cursor: pointer;
}

.close-cross{
  width: 20px;
}

.google-maps{
  border: 0;
  margin-top: 30px;
  border-radius: 5px;
  width: 400px;
  height: 300px;
}

.map-google-head{
  margin-top: 30px;
  font-weight: 600;
}

/* REALIZACJE ZDJECIA */
.subpage-description{
  font-style: italic;
  font-family: 'Poppins';
  color: #262931;
  margin: 40px 5% 0 5% ;
}

.subpage-description p {
  margin-bottom: 10px;
  font-size: 13px;
}

.realizacje-header{
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 20px;
}


.meble{
  min-height: 90vh;
  padding-bottom: 5vh;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-height: 100%;
  gap: 30px;
  padding: 3rem;
  margin-top: 90px;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.35);
  width: 100%; 
  padding-bottom: 75%; 
}

.card-description {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(250, 250, 250, 0.8);
  color: #262931;
  padding: 5px;
  font-size: 13.8px;
  font-style: italic;
  font-family: 'Poppins';
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease-in-out;
}

.card:hover img {
  transform: scale(1.2);
  cursor: pointer;
}

.mini-spinner{
  width: 24px !important;
  height: 24px !important;
  left: 45% !important;
  top: 45% !important;
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in-out;
}

.card:hover .card-overlay {
  cursor: pointer;
  opacity: 1;
}

.card-text {
  cursor: pointer;
  font-style: italic;
  font-family: 'Poppins';
  color: #EDF2F0;
  font-size: 24px;
}

/* POPUP */

.gallery-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.react-responsive-modal-containerCenter:after{
  display: block !important;
  height: auto !important;
}

.modal-content {
  max-width: 100%;
  height: auto;
}

.image-gallery-swipe{
  width: 95%;
  height: 95%;
  margin: 0 auto;
}

.react-responsive-modal-modal{
  max-width: 100vw !important;
  background-color: #EDF2F0 !important;
  border-radius: 5px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image{
  max-height: calc(90vh - 80px) !important;
}

.image-gallery-slide-wrapper{
  width: 60vw !important;
}

.image-gallery-icon:focus{
  outline:none !important;
}

.image-gallery-svg{
  height: 60px !important;
  width: 30px !important;
}

.image-gallery-left-nav, .image-gallery-right-nav{
  margin: 0px 20px !important;
  padding: 0 !important;
}

.image-gallery-play-button,
.image-gallery-fullscreen-button {
  display: none;
}

/* FOOTER */

footer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #262931;
  color: #EDF2F0;
  padding-top: 25px;
}

.contact-numer{
  text-decoration: none;
  color: #262931;
  font-weight: 500;
}

.contact-numer:hover{
  text-decoration: underline;
  font-weight: 600;
}

.contact-map{
  width: 600px;
  height: 450px;
  border: none;
  margin-top: 30px;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
}

.footer-column {
  flex-basis: 50%;
  padding: 10px;
  text-align: center;
  font-style: italic;
  font-family: 'Poppins';
  color: #EDF2F0;
}

.footer-column h4 {
  padding-bottom:25px;
  font-weight: 600;
  letter-spacing: 0.7px;
  font-size: 20px;
}

.footer-ul{
  list-style-type: none;
}

.footer-ul li{
  padding-bottom: 2px;
}

.footer-ul a {
  color: #EDF2F0;
  text-decoration: none;
}
.footer-ul a:hover{
  text-decoration: underline;
  opacity: 0.7;
}

.footer-logo{
  width: 50%;
  border-radius: 10px;
}

.footer-underlogo{
  padding: 20px;
  width: 60%;
  margin: 0 auto;
  font-style: italic;
  font-family: 'Poppins';
  color: #EDF2F0;
}

.footer-description{
  font-style: italic;
  font-family: 'Poppins';
  color: #EDF2F0;
  font-weight: 400;
  padding: 15px;
  text-align: center;
  border-top: 1px solid #fff;
  width: 100%;
}

/* BLOG */
.blog{
  font-style: italic;
  font-family: 'Poppins';
  margin-top: 100px;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 65%;
  margin: 0 auto;
}

.blogimage-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
}

.blogimage-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s;
}

.image-text {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.859) 40%, rgba(0, 0, 0, 0) 100%);
  color: #EDF2F0;
  font-style: italic;
  font-family: 'Poppins';
  height: 150px;
  padding-top: 70px;
  padding-left: 10px;
  font-size: 22px;
}

.blogimage-container:hover img {
  transform: scale(1.2); /* Powiększenie obrazka po najechaniu */
}


/* WPISY */

.wpis-cont{
  margin: 100px 10% 50px 10%;
}

.wpis-img{
  max-width: 500px;
  border-radius: 15px;
}

.wpis-container {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.wpis-container-2 {
  display: flex;
  align-items: center;
}

.wpis-img-2 {
  order: 2;
  margin-left: 20px;
  max-width: 500px;
  border-radius: 15px;
}

.hejg{
  max-height: 70vh;
}
.botmar{
  margin-bottom: 50px;
}
.wpis-text-2 {
  order: 1;
  text-align: left;
}

.text-margin{
  margin-bottom: 50px;
}

.wpis-img {
  margin-right: 20px;
}

.wpis-text {
  text-align: left;
  width: 50%;
  margin-left: 50px;
}

.headers-list{
  margin: 30px 0;
}
.headers-list p {
  margin-bottom: 15px;
}

/* OBSZAR */
.obszar{
  font-style: italic;
  font-family: 'Poppins';
  margin-top: 100px;
  margin-bottom: 2vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.obszar-cont{
  margin: 50px 10% 50px 10%;
}

.grid-container-obszar{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.grid-item-obszar{
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
  font-size: 13px;
}

.grid-container-proces{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.obszar-img{
  max-width: 600px;
  border-radius: 15px;
}

.obszar-containter{
  text-align: center;
  margin: 25px 0;
}

.wpis-text h1{
  margin-bottom: 20px;
}

.wpis-text p{
  margin-bottom: 15px;
}

/* MEDIA QUERIES */

@media (min-width:1500px) {
  .section-header h2,
  .section-header h3{
    font-size: 3.4rem;
  }
  .section-header-about{
    font-size: 2.2rem;
  }
}

@media (max-width:1100px) {
  .section-header h2,
  .section-header h3{
    font-size: 3.4rem;
  }
  .abth{
    font-size: 12px;
  }
  .image-text{
    font-size: 1rem;
  }
  .google-maps{
    width: 350px;
    height: 250px;
  }
  .grid-container-obszar{
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width:965px) {
  .centered-header {
    font-size: 3.5rem;
  }
  .footer-logo,
  .footer-underlogo{
    width: 70%;
  }
  
  .navLink{
    margin: 4px;
  }

  .centered-para {
    font-size: 1.3rem;
  }

  .section-header h2,
  .section-header h3{
    font-size: 2.6rem;
    margin: 0 5px;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin: 10px;
  }

  .contact-form{
    margin-left: 0;
  }

  .contact-form-input,
  .contact-button{
    width: 330px;
  }

  .kafelki-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .wpis-container{
    display: block;
    text-align: center;
  }

  .wpis-container-2{
    display: grid;
  }

  .wpis-img-2{
    order: 1;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .wpis-text-2{
    order: 2;
    text-align: center;
  }

  .wpis-img-2,
  .wpis-img,
  .obszar-img{
    max-width: 100%;
  }

  .wpis-text{
    width: 100%;
    margin-top: 30px;
    text-align: center;
    margin-left: 0;
  }

  .blog-grid {
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  }
}

@media (max-width: 900px) {
  .card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .react-responsive-modal-modal{
    max-width: 800px !important;
  }
  .image-gallery-slide-wrapper{
    width: 82vw !important;
  }
  .navLink{
    font-size: 16px;
  }
  .text h4{
    font-size: 1.5rem;
  }
  .abth{
    font-size: 13px;
  }
  .google-maps{
    width: 300px;
    height: 350px;
  }
  .grid-container-obszar{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width:750px) {
  .centered-header {
    font-size: 3rem;
    margin: 3vh 3vw;
  }
  .kafelki-container{
    padding: 10px;
  }
  .centered-para {
    font-size: 1.1rem;
    margin: 0 5vw;
  }

  .section-below{
    padding: 3vh 0vw;
    text-align: center;
  }

  .grid-container-proces{
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0;
  }
  
  .grid-item-obszar{
    margin: 0;
  }

  .right-column{
    padding: 10px;
  }
  
  .contact-form-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .contact-form {
    width: 100%;
    max-width: 400px;
    margin: 50px auto;
    order: 2;
  }

  .card-grid {
    gap: 20px;
    padding: 2rem;
  }
  
  .contact-details {
    width: 100%;
    max-width: 400px;
    margin: 0px auto;
    text-align: center;
    padding-left: 0;
    order: 1;
  }

  .contact-form-input,
  .contact-button{
    width: 100%;
  }

  .footer-logo{
    width: 80%;
  }
  .footer-underlogo{
    width: 100%;
    padding: 10px;
  }
  .google-maps{
    width: 400px;
    height: 300px;
  }
  .grid-container-obszar{
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width:600px){
  .react-responsive-modal-modal {
      width: 90%;
      max-width: 90%;
    }

  .react-responsive-modal-closeButton{
    right: 2px !important;
    top: 2px !important;
  }
  .card-description{
    font-size: 12px;
  }
  .grid-container-obszar{
    grid-template-columns: repeat(2, 1fr);
  }

  .text h4{
    font-size: 1.1rem;
  }
}

@media (max-width:500px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin: 20px;
    height: auto;
  }

  .kafelki-container{
    padding: 20px;
  }

  .card-grid {
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    gap: 25px;
    padding: 1rem;
  }

  .text h4{
    font-size: 1.5rem;
  }

  .right-column{
    padding: 5px;
    width: 90%;
  }

  .grid-item{
    min-height: 30vh;
  }

  .react-responsive-modal-modal{
    max-width: 100% !important;
    padding: 15px 15px 15px 10px !important;
    margin-top: 7vh !important; 
  }

  .modal-content {
    width: 100%;
  }

  .footer-column{
    flex-basis: 100%;
  }

  .footer-underlogo{
    padding: 20px 5px;
  }

  .footer-ul{
    padding-bottom: 30px;
  }
  .footer-column h4{
    font-size: 20px;
  }

  .blog-grid {
    grid-template-columns: repeat(1, 1fr);
    width: 70%;
  }

  .google-maps{
    width: 350px;
    height: 300px;
  }
  .grid-container-obszar,
  .grid-container-proces{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width:400px) {
  .centered-header {
    font-size: 2.6rem;
    margin: 3vh 3vw;
  }

  .card-description {
    font-size: 11px;
  }

  .arrow-down span {
    width: 30px;
    height: 30px;
  }
  
  .centered-para {
    font-size: 1.1rem;
    margin: 0 5vw;
  }
  .card-text {
    font-size: 18px;
  }
  .footer-description{
    font-size: 14px;
  }
  .google-maps{
    width: 300px;
    height: 300px;
  }
}